<template>
  <div class="report-success-container">
    <h1>ISSUE SUBMITTED</h1>
    <h2>WHAT HAPPENS NEXT</h2>
    <p>
      Thank you for your submission! We’ve received it and will reach out for more details if needed.
    </p>
    <h2>THE INFORMATION YOU SUBMITTED</h2>
    <div class="form-info">
      <div class="input-value name">
        <span>Name:</span>
        <p>{{ formData.name }}</p>
      </div>
      <div class="input-value name">
        <span>Email:</span>
        <p>{{ formData.email }}</p>
      </div>
      <div class="input-value name">
        <span>Issue:</span>
        <p>{{ formData.message }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    formData: Object
  },
  setup (props) {}
}
</script>

<style scoped lang="scss">
.report-success-container {
  max-width: 700px;
  margin: 0 auto;
  text-align: left;
  h1 {
    text-align: center;
    font-size: 35px;
  }
  h2 {
    margin-top: 50px;
  }
  .form-info {
    margin-left: 80px;
  }
  .input-value {
    span,
    p {
      display: inline-block;
    }
    span {
      font-weight: bold;
      width: 120px;
    }
  }
}
</style>
