<template>
  <div class="report-issue-container">
    <transition name="fade-out" @leave="formLeave">
      <ReportIssueForm @FORM-SUBMITTED="onFormSubmit" :isSubmitting="isSubmitting" v-if="!formSubmitted" />
    </transition>
    <transition name="fade-in" @enter="successEnter" @before-enter="successBeforeEnter">
      <ReportIssueSuccess :formData="formData" v-if="showSuccess" />
    </transition>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useRoute } from 'vue-router'
import axios from 'axios'
import gsap from 'gsap'
import { getClientCMSKey } from '@/store/ButterCMSData'
import ReportIssueForm from '@/components/reportIssue/ReportIssueForm'
import ReportIssueSuccess from '@/components/reportIssue/ReportIssueSuccess'
import { ReportIssueConfig } from '@/global.js'

export default {
  name: 'ReportIssue',
  components: {
    ReportIssueForm,
    ReportIssueSuccess
  },
  setup () {
    const formSubmitted = ref(false)
    const showSuccess = ref(false)
    const formData = ref({})
    const route = useRoute()
    const isSubmitting = ref(false)

    const onFormSubmit = async (_formData) => {
      isSubmitting.value = true
      const success = await sendReportIssueEmail(_formData)
      if (success) {
        formSubmitted.value = true
        formData.value = _formData
      }
      isSubmitting.value = false
    }

    const formLeave = (el, done) => {
      gsap.to('.right', { duration: 0.2, x: 100 })
      gsap.to('.left', { duration: 0.2, x: -100 })
      gsap.to('.text', { duration: 0.2, scale: 0 })
      gsap.to(el, {
        duration: 0.2,
        autoAlpha: 0,
        onComplete: function () {
          done()
          showSuccess.value = true
        }
      })
    }

    const successBeforeEnter = (el) => {
      gsap.set(el, { autoAlpha: 0 })
    }

    const successEnter = (el, done) => {
      gsap.to(el, { duration: 1, autoAlpha: 1, onComplete: done })
    }

    const sendReportIssueEmail = async (formData) => {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Access-Token': ReportIssueConfig.functionAccessToken
        }
      }

      const content = {
        name: formData.name,
        email: formData.email,
        message: formData.message,
        url: route.params.url,
        microsite: getClientCMSKey()
      }

      try {
        const response = await axios.post(ReportIssueConfig.functionUrlEndpoint, content, config)
        if (response.status === 200) {
          return true
        } else {
          console.error('Error:: Report Issue Email', response)
          return false
        }
      } catch (error) {
        console.error('Error:: Report Issue Email', error)
        return false
      }
    }

    return { onFormSubmit, formSubmitted, formLeave, showSuccess, successEnter, successBeforeEnter, formData, isSubmitting }
  }
}
</script>

<style scoped lang="scss">
.report-issue-container {
  margin: 0 auto;
}
</style>
